:root {
  --main-color: #49158b;
  --purple-hover: #7524de;
  --light-color: #ffffff;
  --dark-color: #000000;
  --green-color: #6cffa7;
  --green-hover: #8bdfad;
  --pink-color: #ff3c77;
  --blue-color: #08bfff;
  --orange-color: #ff4f2b;
  --yellow-color: #ffae16;
  --grey-dark-color: #495057;
  --grey-light-color: #ced4da;
  --toastify-toast-width: 380px;
  --toastify-color-info: #7524de;
  --toastify-color-success: #6cffa7;
  --toastify-color-warning: #ffae16;
  --toastify-color-error: #ff3c77;
}

/* Sidebar */
.activeClicked > li {
  background: #082d45ff !important;
  color: white !important;
}

.page-item.active .page-link {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: white !important;
}

.page-link {
  color: rgb(13, 67, 103) !important;
}

.page-link_color {
  color: rgb(13, 67, 103) !important;
}

.head-div {
  background-color: #050f16 !important;
}

.lalqgO {
  border-bottom: 1px solid #929eaa;
}

/* Text */
.small-text {
  font-size: 0.9rem;
  font-weight: 500;
  color: #6a717a;
}

/* Buttons */

.btn-container {
  background-color: var(--main-color);
  padding: 1.5rem;
}

.fpb-btn {
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
  border-width: 2px;
  color: var(--dark-color) !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.fpb-btn-purple {
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  border-width: 2px;
  color: white !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  transition: all 0.5s;
}

.fpb-btn-purple:hover {
  background-color: var(--purple-hover) !important;
  border-color: var(--purple-hover) !important;
  color: white !important;
}

.fpb-btn-default {
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
  border-width: 2px;
  color: var(--dark-color) !important;
}

.fpb-btn-default:hover {
  background-color: var(--green-hover) !important;
  border-color: var(--green-hover) !important;
  border-width: 2px;
}

.fpb-btn:hover {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
  color: white !important;
}

.fpb-btn:active {
  background-color: var(--dark-color) !important;
  color: white !important;
}

.fpb-btn-small {
  background-color: #f8f9fa !important;
  border-color: white !important;
  color: var(--dark-color) !important;
  margin-top: 1rem !important;
}

.fpb-btn-chart {
  background-color: #f8f9fa !important;
  border-color: white !important;
  color: var(--dark-color) !important;
  margin-right: 12px;
}

#dropdown-download-btn-sm {
  margin-left: 0rem !important;
  padding: 0rem !important;
  padding-right: 0rem !important;
}

#dropdown-download-btn-lg {
  padding-left: 0rem !important;
}

.fpb-btn-inverse {
  background-color: var(--light-color) !important;
  border-color: var(--green-color) !important;
  border-width: 2px;
  color: var(--dark-color) !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.fpb-btn-inverse:hover {
  background-color: var(--dark-color) !important;
  border-color: var(--dark-color) !important;
  color: white !important;
}

.fpb-btn-inverse:active {
  background-color: var(--dark-color) !important;
  color: white !important;
}

.fpb-btn:focus {
  border-color: var(--green-color) !important;
  box-shadow: 0 0 0 0.2rem rgba(173, 117, 226, 0.178) !important;
  background-color: var(--green-color) !important;
  color: black !important;
}

.fpb-btn-inverse:focus {
  border-color: var(--main-color) !important;
  box-shadow: 0 0 0 0.2rem rgba(173, 117, 226, 0.178) !important;
  background-color: var(--dark-color) !important;
  color: white !important;
}

.form-control:focus {
  border-color: rgba(13, 67, 103, 0.527) !important;
  box-shadow: 0 0 0 0.2rem rgba(13, 67, 103, 0.116) !important;
}

.fpb-btn-inverse a {
  text-decoration: none !important;
}

.fpb-btn a {
  text-decoration: none !important;
}

.fpb-btn-inverse a:hover {
  color: white !important;
}

.fpb-btn-secondary {
  background-color: #e3e3e3 !important;
  border-color: #e3e3e3 !important;
  color: var(--dark-color) !important;
  width: 7rem !important;
}

.fpb-btn-secondary:hover {
  background-color: #d3d3d3 !important;
  color: var(--dark-color);
}

.fpb-btn-inverse a {
  color: var(--dark-color) !important;
}

.fpb-spinner {
  color: var(--main-color) !important;
  height: 3rem !important;
  width: 3rem !important;
  border-width: 5px !important;
}

.fpb-progress-bar {
  color: var(--main-color) !important;
}

.fpb-spinner-sm {
  color: var(--grey-dark-color) !important;
  height: 1.2rem !important;
  width: 1.2rem !important;
  border-width: 2px !important;
  margin-bottom: 0.2px !important;
}

.fade-in {
  transition: opacity 0.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.btn-search {
  background-color: rgb(196, 196, 196);
  border-color: gray-dark;
}

.btn-clearable {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  cursor: default;
}

.btn-clearable:hover {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
}

.btn-clearable:checked {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
}

.btn-svg {
  cursor: pointer;
}

.clear-container {
  color: hsl(0, 0%, 80%);
  display: flex;
  transition: color 150ms;
  box-sizing: border-box;
}

.clear-container:hover {
  color: hsl(0, 0%, 50%);
}

.clear-x {
  display: inline-block;
  fill: currentColor;
  line-height: 1;
  stroke: currentColor;
  stroke-width: 0;
}

.btn-search:hover {
  background-color: rgb(139, 139, 139);
  border-color: transparent;
}

.icon-search {
  padding: 0px 1px;
  color: gray;
  cursor: pointer;
}

.icon-search:hover {
  color: var(--dark-color);
}

.mainComponent {
  background-color: rgb(231 237 242 / 20%);
}

/* Tables */

table {
  background-color: white !important;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(128, 128, 128, 0.116);
  text-align: left;
}

.pagination {
  font-size: 11px !important;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: rgb(253, 253, 253) !important;
}

table td,
table th {
  padding-left: 2rem !important;
}

th {
  color: gray;
  font-weight: 400;
}

td {
  white-space: nowrap;
}

table {
  /* display: block;
  overflow-x: auto;
  white-space: nowrap; */
}

.actions i {
  color: #0d4367ff;
}

/* Table Buttons */

#edit {
  /* background-color: #0d4367ff !important; */
  color: white !important;
}

#delete {
  /* background-color: rgb(21, 83, 124) !important; */
}

#add {
  text-decoration: none !important;
}

#add:before {
  content: '+';
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  /* background: url('logo.svg') no-repeat; */
}

#edit:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f044';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  color: #4b4b4b;
}

#delete:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f2ed';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  color: #4b4b4b;
}

#save:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f0c7';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  color: #4b4b4b;
}

#commit:before {
  font-family: 'Font Awesome\ 5 Free';
  content: '\f0c7';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  color: #4b4b4b;
}

#cancel:before {
  font-family: 'Font Awesome\ 5 Free';
  content: '\f00d';
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  color: var(--pink-color);
}

.disabled {
  opacity: 0.3 !important;
}

/* Sidebars */
.sidebar-module {
  background-color: var(--light-color) !important;
  position: fixed;
  top: 0;
  z-index: 100;
  height: 100vh;
}

.sidebar-module .nav-item {
  background-color: white;
}

#dropdown-basic-button {
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
  border-width: 2px;
  color: var(--dark-color) !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

#dropdown-secondary-button {
  background-color: var(--light-color) !important;
  border-color: var(--green-color) !important;
  border-width: 2px;
  color: var(--dark-color) !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

/* Table form */
.fpb-table-form {
  background-color: rgb(240 240 240);
  border-radius: 10px;
  border: 1px solid rgb(223 223 223);
}

.fpb-sidebar a:hover {
  text-decoration: none !important;
}

.fpb-sidebar li:hover {
  background-color: #082d45ff !important;
}

/* Home cards */
.fpb-cards {
  gap: 1rem;
}

.fpb-cards a {
  text-decoration: none;
}

.fpb-cards .card-title {
  color: white;
}

/* Production Order Process cards */
.process-order-card {
  gap: 1rem;
  font-size: 1vw !important;
  background-color: #fafafa !important;
}

.activeSidebar {
  /* background-color: black !important; */
  color: var(--pink-color) !important;
  font-weight: bold !important;
}

.fpb-modal .modal-dialog {
  max-width: 80%;
}

/* selected row  */
.table-active td {
  background-color: #e7e6e6 !important;
}

.fpb-error {
  position: absolute;
  color: var(--pink-color);
  font-size: 0.6rem;
}

.rdrDateDisplayWrapper {
  display: none;
}

/* SelectItems */
.content-select::-ms-expand {
  display: none;
}

.content-select {
  padding-right: 5px;
  padding-left: 5px;
  width: 50%;
  position: relative;
}

.select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #838282;
}

.icon-select {
  position: absolute;
  top: 44px;
  right: 12px;
  width: 1.55em;
  height: 1.5em;
  color: #bebebef5;
  background: transparent;
}

/* CustomSelect */
.description-select {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0%;
  margin-left: 2px;
}

.description-filters {
  font-size: 0.8rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-left: 2px;
  margin-top: 0%;
}

.label {
  /*font-weight: 600;*/
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 4px;
}

/*ProductPerProductionOrder*/

.title {
  font-size: x-large;
  margin-left: 2px;
  margin-top: 8px;
}

.big-title {
  font-weight: bolder;
  font-size: x-large;
  margin: 0%;
  padding: 0%;
}

.stepper {
  border-left: blueviolet 2px dashed;
}

.more {
  background-color: #ebf5ff;
  color: var(--main-color);
  border: none;
  padding: 8px;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.more:hover {
  background-color: var(--blue-color);
}

.status {
  margin-left: auto;
  color: var(--main-color);
}

/* Associate orders */
#dropdown-select-button {
  background-color: #082d45 !important;
  border-color: #082d45 !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

/* Graph axis */
.axisX path,
.axisY path {
  stroke: rgb(111, 111, 111);
  stroke-width: 1px;
}

/* Graph tooltip cards */
.tooltipContainerb1,
.tooltipContainerb2,
.tooltipContainerb3,
.tooltipContainerb4,
.tooltipContainerb5,
.tooltipContainerb6 {
  fill: white;
  stroke: #000;
  stroke-width: 0.5px;
}

/*Indicator filters transition*/
.filtersContainer#hide {
  overflow: hidden;
  height: 3%;
  opacity: 0;
  transition: height 400ms 400ms, opacity 400ms 0ms;
}

.filtersContainer#show {
  opacity: 1;
  overflow: visible;
}

.filtersContainer {
  opacity: 1;
  transition: height 400ms 400ms, opacity 400ms 0ms;
}

/*industrial-production-order*/
.secondary {
  border-radius: 32px;
  background-color: #929eaa;
}

.product {
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
  border-radius: 50%;
  color: var(--dark-color);
  background-color: var(--dark-color);
}

/*ProcessControlSection*/
.column-container {
  padding: 8px;
  width: auto;
  height: auto;
  margin: 0px 8px;
  background: #f8f0f0;
  border: 4px solid #f8f0f0;
  border-radius: 8px;
}

.profile-navigation {
  flex-direction: row;
}

/* Adjust the size of logo image in home */
.logo-image {
  max-width: 300px;
}

@media screen and (max-width: 768px) {
  .logo-image {
    max-width: 95%;
  }
}

.main-color {
  color: var(--main-color) !important;
}

.modalqr-label {
  background-color: var(--yellow-color);
  color: var(--dark-color);
  display: inline;
  padding: 0px 10px;
  border-radius: 5px;
}

.modalqr-printers {
  width: 11rem;
  display: block;
}

/*Badges colors*/

.green {
  color: var(--green-color);
}

.gray {
  color: var(--light-color);
}

.blue {
  color: var(--blue-color);
}

.purple {
  color: white !important;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  transition: 0.25s;
}

.purple:hover,
.purple:focus {
  background-color: var(--purple-hover) !important;
  border-color: var(--purple-hover) !important;
}

.purple:focus {
  font-weight: bold;
  outline: none !important;
}

.cyan {
  background-color: var(--green-color) !important;
  border-color: var(--green-color) !important;
}

/*control buttons*/
.control-button {
  color: white !important;
  background-color: var(--main-color) !important;
  border-color: var(--main-color) !important;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  transition: 0.5s;
}

.control-button:hover,
.control-button:focus {
  background-color: var(--purple-hover) !important;
  border-color: var(--purple-hover) !important;
}

.clock {
  background-color: var(--main-color);
  height: 60px;
  width: auto;
  border-radius: 8px;
  color: var(--yellow-color);
  font-size: x-large;
  font-weight: bold;
  align-items: center;
  text-align: center;
  padding-top: 8%;
}

/* modal backdrop fix */
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1050 !important;
}

/* icon to confirm product per production orders */
.check-icon {
  animation: scaleAnimation 0.5s ease-out;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

/* Resizing view-box so it is displayed within the window*/
.popover {
  max-width: 100%;
}

div.d-flex.flex-column {
  margin-right: 5px !important;
}

/*Industrial indicators*/
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 18px;
  grid-auto-rows: minmax(100px, auto);
}

.time {
  grid-column: 1 / 3;
  grid-row: 1;
}

.time-percentages {
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}

.event-time {
  grid-column: 1;
  grid-row: 2 / 5;
}

.event-time-percentages {
  grid-column: 3;
  grid-row: 3;
}

.dark-button {
  background-color: var(--dark-color);
  border-color: var(--dark-color);
}

.select-group {
  min-width: 250px;
  max-width: fit-content;
}

.changepassword-toogle-icon {
  position: absolute;
  top: 317px;
  right: 60px;
  z-index: 1000;
  cursor: pointer;
}

.changepassword-toogle-icon2 {
  position: absolute;
  top: 430px;
  right: 60px;
  z-index: 1000;
  cursor: pointer;
}

.password-toogle-icon {
  position: absolute;
  top: 205px;
  right: 60px;
  z-index: 1000;
  cursor: pointer;
}

.password-toogle-icon2 {
  position: absolute;
  top: 320px;
  right: 60px;
  z-index: 1000;
  cursor: pointer;
}

.currentpassword-toogle-icon {
  position: absolute;
  top: 92px;
  right: 60px;
  z-index: 1000;
  cursor: pointer;
}

.switch-toggle {
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 150px;
  height: 50px;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  align-items: flex-end;
}

.switch-checkbox {
  height: 0;
  width: 0;
  display: none;
}

.switch-toggle .switch-slider {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  transition: all 0.4s ease-in-out;
}

.switch-toggle .switch-checkbox:checked + .switch-slider {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-toggle:active .switch-slider {
  width: 60px;
}

.switch-toggle .switch-labels::after {
  content: attr(data-off);
  position: absolute;
  right: 20px;
  top: 15px;
  color: #000000;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0, 4);
  transition: all 0.4s ease-in-out;
  font-weight: bold;
}

.switch-toggle .switch-labels::before {
  content: attr(data-on);
  position: absolute;
  left: 20px;
  top: 15px;
  color: #000000;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
  font-weight: bold;
}

.switch-toggle input:checked ~ .switch-labels::before {
  opacity: 1;
}

.switch-toggle input:checked ~ .switch-labels::after {
  opacity: 0;
}
