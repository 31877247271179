body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  font-family: 'Avenir', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600 !important;
  font-family: 'Champagne&LimousinesBold', sans-serif !important;
}

h1 {
  font-size: 40px;
}

@font-face {
  font-family: 'Champagne&LimousinesBold';
  src: local('Champagne&Limousines'),
    url('./fonts/Champagne_&_Limousines_Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Avenir';
  src: local('Avenir'), url('./fonts/Avenir.ttc') format('truetype');
}
